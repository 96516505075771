#canvas-container {
    height: 100vh;
    background-color: #323232;
    user-select: none;
}

.title {
    margin: auto;
    width: 100vw;
    padding: 10px;
    color: white;
    text-align: center;
    position: absolute;
    top: 0;
}

.content-center {
    margin: auto;
    width: 100vw;
    padding: 10px;
    text-align: center;
}

.override-center {
    width: 100% !important;
}

.foot-note {
    margin: auto;
    width: 100vw;
    padding: 10px;
    color: white;
    position: absolute;
    bottom: 0;
}

.my-name {
    user-select: none;
    font-size: 40pt;
}

.my-name-mobile {
    user-select: none;
    font-size: 25pt;
}

.my-title {
    font-size: 25pt;
}

.my-title-mobile {
    font-size: 15pt;
}

.content {
    width: 280px;
    height: 280px;
    /*background: #f0f0f0;*/
    border-radius: 3px;
    overflow-y: auto;
    padding: 0;
}

.page-content {
    margin: 2% 10% 2% 2%;
    width: 100vw;
    height: auto;
}

.wrapper {
    padding: 10px;
    width: 540px;
    height: 540px;
    max-width: 540px;
    transform: scale(0.5);
    transform-origin: top left;
}

.classic-header {
    margin: auto;
    width: 100vw;
    padding: 10px;
    color: white;
    text-align: center;
    background-color: #323232;
}

#canvas-container a:link {
    color: whitesmoke;
}

#canvas-container a:visited {
    color: gray;
}

.page-content a:link {
    color: deepskyblue !important;
}

.page-content a:visited {
    color: gray;
}

.section-title {
    width: 100%;
    color: white;
    background-color: #323232;
    padding: 10px;
}

.fill-max {
    max-width: 98vw;
}

.fill-most {
    max-width: 96vw;
}

img.wrap {
    float: left;
    margin: 5px;
}
